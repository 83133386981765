.ListGallery::-webkit-scrollbar {
    -webkit-appearance: none;
}

.ListGallery::-webkit-scrollbar:horizontal {
    width: 8px;
}

.ListGallery::-webkit-scrollbar:vertical {
    height: 1px;
}

.ListGallery::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; 
    background-color: rgba(0, 0, 0, .5);
}

.ImageItemsContainer {
    max-width: 100vw;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
